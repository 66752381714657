<template>
	<div style="position:relative;">
		<!-- 任务1 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;">基本任务</div>
			<el-checkbox  v-model="baseInfoChecked" style="margin-top: 15px;">完善个人信息</el-checkbox>
			<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">个人信息包括:头像、姓名、手机号、性别、生日、地区、微信号；任务取消后，已发放的成长值不扣除</div>
			<div style="font-size: 14px;color:#303133;margin-top: 15px;margin-left: 24px;">
				<span>完成后，获得</span>
				<el-input  size="mini" v-model="baseInfoValue" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<!-- <el-input-number size="mini" :min="0" v-model="baseInfoValue" style="width: 140px;margin: 0 5px;"></el-input-number> -->
				<span>个成长值</span>
			</div>
			<el-checkbox  v-model="phoneChecked" style="margin-top: 20px;">绑定手机号</el-checkbox>
			<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">任务取消后，已发放的成长值不扣除</div>
			<div style="font-size: 14px;color:#303133;margin-top: 15px;margin-left: 24px;">
				<span>绑定成功后，获得</span>
				<el-input  size="mini" v-model="phoneValue" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<!-- <el-input-number size="mini" :min="0" v-model="phoneValue" style="width: 140px;margin: 0 5px;"></el-input-number> -->
				<span>个成长值</span>
			</div>
		</div>
		
		<!-- 任务2 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;">消费任务</div>
			<el-checkbox  v-model="buyProductChecked" style="margin-top: 15px;">购买商品</el-checkbox>
			<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;line-height: 18px;">根据商品实付金额计算；订单完成后发放成长值。若订单内商品全部退款成功，扣减所有成长值；部分退款成功，根据商品退款金额比例扣减。任务取消后，已发放的成长值不扣除</div>
			<div style="font-size: 14px;color:#303133;margin-top: 15px;margin-left: 24px;">
				<span>商品每消费</span>
				<el-input  size="mini" v-model="buyProductPrice" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<!-- <el-input-number 
				size="mini" 
				:min="0" 
				:step="1"
				v-model="buyProductPrice" 
				style="width: 140px;margin: 0 5px;"></el-input-number> -->
				<span>元，获得</span>
				<el-input  size="mini" v-model="buyProductValue" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<!-- <el-input-number size="mini" :min="0" v-model="buyProductValue" style="width: 140px;margin: 0 5px;"></el-input-number> -->
				<span>个成长值</span>
			</div>
			<el-checkbox  v-model="orderChecked" style="margin-top: 20px;">完成下单</el-checkbox>
			<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">订单完成后发放成长值。订单退款成功后，扣减订单成长值；若订单内部分商品退款，不扣减成长值。任务取消后，已发放的成长值不扣除</div>
			<div style="font-size: 14px;color:#303133;margin-top: 15px;margin-left: 24px;">
				<span>每完成</span>
				<el-input  size="mini" v-model="orderNumber" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<!-- <el-input-number size="mini" :min="0" v-model="orderNumber" style="width: 140px;margin: 0 5px;"></el-input-number> -->
				<span>笔订单，获得</span>
				<el-input  size="mini" v-model="orderValue" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<!-- <el-input-number size="mini" :min="0" v-model="orderValue" style="width: 140px;margin: 0 5px;"></el-input-number> -->
				<span>个成长值</span>
			</div>
		</div>
		
		<!-- 任务3 -->
		<!-- margin-bottom: 50px; -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;position:relative;">
			<div v-if="!IsOpen" style="backgroung:#fff;width:100%;height:100%;position:absolute;left:0;top:0;background-color: rgba(255,255,255,0.4);z-index: 999;"></div>

			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;">活跃任务</div>
			<el-checkbox  v-model="messionChecked" style="margin-top: 15px;">每日签到</el-checkbox>
			<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">每日签到成功后发放成长值。任务取消后，已发放的成长值不扣除</div>
			<div style="font-size: 14px;color:#303133;margin-top: 15px;margin-left: 24px;">
				<span>每日签到成功，获得</span>
				<el-input  size="mini" v-model="messionValue" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<!-- <el-input-number size="mini" :min="0" v-model="messionValue" style="width: 140px;margin: 0 5px;"></el-input-number> -->
				<span>个成长值</span>
			</div>
		</div>
		<!-- 任务四 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 50px;position:relative;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;">充值任务</div>
			<el-checkbox  v-model="rechargeChecked" style="margin-top: 15px;" :disabled="!IsOpenMallTopUp">充值余额</el-checkbox>
			<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">每次充值成功后发放成长值，任务取消后，已发放的成长值不扣除</div>
			<div v-if="!IsOpenMallTopUp" style="font-size: 12px;padding-left: 22px;margin-top: 15px;"><span style="color: #F56C6C;">余额充值功能已关闭，开启后该配置将恢复正常,</span><span style="color:#409EFF;cursor:pointer;" @click='ToPen'>去开启</span></div>
			<div style="font-size: 14px;color:#303133;margin-top: 15px;margin-left: 24px;">
				<span>每充值成功1元，获得</span>
				<el-input :disabled="!IsOpenMallTopUp"  size="mini" v-model="rechargeValue" style="width:90px;margin: 0 8px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
				<span>个成长值</span>
			</div>
		</div>
		<!--  -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 20px;display: flex;justify-content: center;">
			<el-button style="width: 120px;margin-left: 20px;" type="primary" @click="valida">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index';
	import {
		TaskConfigEdit,
		TaskConfigList
	} from '@/api/TurnTomySelf'
	
	export default {
		data(){
			return{
				goUrls: config.GO_URL,
				IsOpen : false,
				taskPoints:'',
				checked:false,
				
				//个人信息
				baseInfoChecked: false,
				baseInfoValue: 0,
				
				//绑定手机号
				phoneChecked: false,
				phoneValue: 0,
				
				//购买产品
				buyProductChecked: false,
				buyProductPrice: 0,
				buyProductValue: 0,
				
				// 完成下单
				orderChecked: false,
				orderNumber: 0,
				orderValue: 0,
				
				messionChecked: false,
				messionValue: 0,
				
				//充值任务
				rechargeChecked:false,
				rechargeValue:0,
				
				IsOpenMallTopUp:false,
			};
		},
		beforeMount() {
			this.IsOpenMallTopUp = window.localStorage.getItem('IsOpenMallTopUp') == 'true'?true:false
			this.getData();
		},
		methods:{
			//去开启
			ToPen(){
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/Customer/storedrule'
				window.open(url)
			},
			async getData(){
				const res = await TaskConfigList();
				if (res.IsSuccess){
					this.IsOpen = res.Result[0].IsOpen
					this.ruleForm = res.Result

					this.baseInfoChecked = this.ruleForm[0].GiveGrowthValue > 0;
					this.baseInfoValue = this.ruleForm[0].GiveGrowthValue;

					this.phoneChecked = 	this.ruleForm[1].GiveGrowthValue > 0;
					this.phoneValue = this.ruleForm[1].GiveGrowthValue;

					this.buyProductChecked = (	this.ruleForm[2].EachFull>0 && 	this.ruleForm[2].GiveGrowthValue>0) ? true:false;
					this.buyProductPrice = this.ruleForm[2].EachFull;
					this.buyProductValue = this.ruleForm[2].GiveGrowthValue;

					this.orderChecked = (	this.ruleForm[3].EachFull>0 && 	this.ruleForm[3].GiveGrowthValue>0) ? true:false;
					this.orderNumber = this.ruleForm[3].EachFull;
					this.orderValue = this.ruleForm[3].GiveGrowthValue;

					this.messionChecked = this.ruleForm[4].GiveGrowthValue > 0;
					this.messionValue = this.ruleForm[4].GiveGrowthValue;
					
					this.rechargeChecked = this.ruleForm[5].GiveGrowthValue > 0;
					this.rechargeValue = this.ruleForm[5].GiveGrowthValue
				}
			},
			async saveBtn(){
				const res = await TaskConfigEdit({
					Configlist: this.ruleForm
				})
				if (res.IsSuccess){
					this.$message({showClose: true,
						type: 'success',
						message: '配置成功'
					})
					this.getData()
				}
				
			},
			valida(){
				var count = 0;
				if (this.baseInfoChecked){
					if (!this.baseInfoValue){
						this.showTip('请配置任务完成后可获得的成长值');
						return false;
					}else{
						this.ruleForm[0].GiveGrowthValue = this.baseInfoValue
						count ++;
					}
				}else{
					this.ruleForm[0].GiveGrowthValue = 0
				}
				if (this.phoneChecked){
					if (!this.phoneValue){
						this.showTip('请配置绑定成功后可获得的成长值');
						return false;
					}else{
						this.ruleForm[1].GiveGrowthValue = this.phoneValue
						count ++;
					}
				}else{
					this.ruleForm[1].GiveGrowthValue = 0
				}
				if (this.buyProductChecked){
					if (!this.buyProductPrice){
						this.showTip('请输入商品消费金额');
						return false;
					}else if (!this.buyProductValue){
						this.showTip('请配置购买商品后可获得的成长值');
						return false;
					}else{
						this.ruleForm[2].EachFull = this.buyProductPrice
						this.ruleForm[2].GiveGrowthValue = this.buyProductValue
						count ++;
					}
				}else{
					this.ruleForm[2].EachFull = 0
					this.ruleForm[2].GiveGrowthValue = 0
				}
				if(this.orderChecked){
					if (!this.orderNumber){
						this.showTip('请输入订单笔数');
						return false;
					}else if (!this.orderValue){
						this.showTip('请配置完成下单可获得的成长值');
						return false;
					}else{
						this.ruleForm[3].EachFull = this.orderNumber
						this.ruleForm[3].GiveGrowthValue = this.orderValue
						count ++;
					}
				}else{
					this.ruleForm[3].EachFull = 0
					this.ruleForm[3].GiveGrowthValue = 0
				}
				if (this.messionChecked){
					if (!this.messionValue){
						this.showTip('请配置每日签到成功后可获得的成长值');
						return false;
					}else{
						this.ruleForm[4].GiveGrowthValue = this.messionValue
						count ++;
					}
				}else{
					this.ruleForm[4].GiveGrowthValue = 0
				}
				
				
				//充值任务
				if(this.rechargeChecked){
					if (!this.rechargeValue){
						this.showTip('请设置充值任务可获得的成长值');
						return false;
					}
					else if(Number(this.rechargeValue)>99999 || Number(this.rechargeValue)<1){
						this.showTip('成长值请设置在1~99999之间,整数');
						return false;
					}
					else{
						this.ruleForm[5].GiveGrowthValue = this.rechargeValue
						count ++;
					}
				}
				else{
					this.ruleForm[5].GiveGrowthValue = 0
				}
				
				if (!count){
					this.showTip('请至少配置一项任务')
					return false;
				}else{
					this.saveBtn()
				}
			},
			showTip(str){
				this.$message({showClose: true,
					type: 'error',
					message: str
				})
			},
		}
	}
</script>

<style lang="less" scoped>
</style>
